import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject, EMPTY, Observable, of, Subject,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map, shareReplay, switchMap, tap,
} from 'rxjs/operators';

import { IPageResult } from 'src/app/entities/global';
import { IGroup, IRestaurant } from 'src/app/entities/restaurant';
import { AuthService, AuthState } from '../auth.service';
import { StoreService } from '../store.service';
import { ProfileState, UserService } from './user.service';
import { isGroupPage } from 'src/app/modules/group-dashboard/services/group-dashboard.service';
import { UserRole } from 'src/app/entities/user-role';

const LSKEY_SELECTED_RESTAURANT = 'selected_restaurant';
export const LSKEY_SELECTED_GROUP = 'selected_group';
export const LSKEY_SELECTED_FRANCHISE = 'selected_franchise';

@Injectable({
  providedIn: 'root',
  })
export class RestaurantService {
  /**
   * A list of the session user's assigned restaurants (meant for non-admin users)
   */
  readonly restaurants$: Observable<IRestaurant[] | IGroup[]>;
  readonly groups$: Observable<IGroup[]>;	
  private _restaurants$: Observable<IPageResult<IRestaurant | IGroup>>;
  /**
   * A single origin for a change in the users selected/focused restaurant
   */
  private selectRestaurantSubject = new BehaviorSubject<IRestaurant>(null);
  readonly selectedRestaurant$ = this.selectRestaurantSubject.asObservable();

  private selectedGroupSubject = new BehaviorSubject<IGroup>(null);
  readonly selectedGroup$ = this.selectedGroupSubject.asObservable();	

  private selectedFranchiseSubject = new BehaviorSubject<IGroup>(null);
  readonly selectedFranchise$ = this.selectedFranchiseSubject.asObservable();		

  constructor(
    private store: StoreService,
    private http: HttpClient,
    private me: UserService,
  ) {
    // load last stored selection - Restaurant
    const sr = this.store.get(LSKEY_SELECTED_RESTAURANT);
    if (sr) { this.selectRestaurantSubject.next(JSON.parse(sr) as IRestaurant); }

    // load last stored selection - Group
    const sg = this.store.get(LSKEY_SELECTED_GROUP);
    if (sg) { this.selectedGroupSubject.next(JSON.parse(sg) as IGroup); }		

    // load last stored selection - Franchise
    const sf = this.store.get(LSKEY_SELECTED_FRANCHISE);
    if (sf) { this.selectedFranchiseSubject.next(JSON.parse(sf) as IGroup); }				

    this._restaurants$ = this.me.profile.pipe(
      map((value) => !!value.profile),
      distinctUntilChanged(),
      tap((hasProfile) => hasProfile || this.deselectRestaurant()),
      switchMap((hasProfile) => (hasProfile ? this.getRestaurants(null, 999) : EMPTY)),
      shareReplay(1), // avoid re-requests for multipe subscriptions
    );
    this.restaurants$ = this._restaurants$.pipe(map((page) => page.list));
  }

  /**
   * RxJs operator method to setup server-side or client-side restaurant filtering.
   *
   * INPUT STREAM: parameters relavant to restaurants.
   *
   * OUTPUT STREAM: a page result with a list of restaurants filtered either
   * server-side of client-side depending on the user role.
   *
   * @returns RxJs operator
   */
  filterToList() {
    return (
      filter?: Observable<{
        name?: string,
        user_id?: string,
        restaurant_set_id?: string,
				profile?: any,
				url?: any,
      }>,
    ): Observable<IPageResult<IRestaurant | IGroup>> => this.me.hasAllRestaurantsVisible$.pipe(
      switchMap((hasAllRestaurantsVisible) => {
        if (hasAllRestaurantsVisible) {
          // server-side search
          // here the filter parameters are sent to the server
          return filter.pipe(
            switchMap((search) => {
							/*if (search.profile && search.url) {
								const role = search.profile.role_name as UserRole;
								const isGroup = isGroupPage(search.url, role);
								if (search.name && !Number.isNaN(+(search.name))) { // get Group/Rest by group_id/id
									return isGroup ? this.getGroup(search.name, true) : this.getRestaurant(search.name, true);
								} else { // get all restaurants/groups
									return isGroup ? this.getGroups(search, 16) : this.getRestaurants(search, 800);
								}
							}*/
              if (search.name && !Number.isNaN(+(search.name))) {
								return this.getRestaurant(search.name, true);
              }
              return this.getRestaurants(search, 16);
            }),
          );
        }

				return filter.pipe(
					switchMap((f) => {
						// if (f.name.length <= 0) return of({ list: [], total: 0, limit: 0, offset: 0});
						const group = this.getGroup(f.name ?? '', true);
						const allGroups = this.getGroups(f, 16);
						const role = f.profile.role_name as UserRole;
						const isGroup = isGroupPage(f.url ?? '', role);
						const gSearch = f.name && !Number.isNaN(+(f.name)) ? group : allGroups;
						const $ = isGroup ? gSearch : this._restaurants$;
						return $.pipe(
							map((page) => {
								const pageClone = { ...page };
								let { list } = page;
								if (f.name) {
									list = list.filter((r) => r.name.toLowerCase().includes(f.name.toLowerCase()));
								}
								pageClone.list = list;
								return pageClone;
							}),
						)
					}
				));				


        // client-side search -- ******NB: code below is PRE September 2024!!!

        // here the filter is used on the results of the last request of all restaurants / groups
        /**return filter.pipe(switchMap((f) => this._restaurants$.pipe(
          map((page) => {
            const pageClone = { ...page };
            let { list } = page;
            if (f.name) {
              list = list.filter((r) => r.name.toLowerCase().includes(f.name.toLowerCase()));
            }
            pageClone.list = list;
            return pageClone;
          }),
        )));*/


      }),
    );
  }

  /**
   * Make an app-wide restaurant selection
   * @param restaurant The restaurant that becomes the user's focus
   */
  selectRestaurant(restaurant: IRestaurant) {
    this.store.set(LSKEY_SELECTED_RESTAURANT, JSON.stringify(restaurant));
    this.selectRestaurantSubject.next(restaurant);
  }

  /**
   * Make an app-wide group selection
   * @param group The group that becomes the user's focus
   */
  selectGroup(group: IGroup) {
		group.id = group.group_id;
    this.store.set(LSKEY_SELECTED_GROUP, JSON.stringify(group));
    this.selectedGroupSubject.next(group);
  }	


  /**
   * Make an app-wide franchise selection
   * @param franchise The franchise that becomes the user's focus
   */
  selectFranchise(franchise: IGroup) {
		franchise.id = franchise.group_id;
    this.store.set(LSKEY_SELECTED_FRANCHISE, JSON.stringify(franchise));
		this.selectedFranchiseSubject.next(franchise);
  }		

  /**
   * Clear the last selected selection
   */
  deselectRestaurant() {
    this.store.remove(LSKEY_SELECTED_RESTAURANT);
    this.selectRestaurantSubject.next(null);
  }

  /**
   * Clear the last selected group selection
   */
  deselectGroup() {
    this.store.remove(LSKEY_SELECTED_GROUP);
    this.selectedGroupSubject.next(null);
  }

  /**
   * Clear the last selected franchise selection
   */
  deselectFranchise() {
    this.store.remove(LSKEY_SELECTED_FRANCHISE);
    this.selectedFranchiseSubject.next(null);
  }	

  /**
   * Request groups from the server
   * @param search Filter applied to the request
   * @param limit Page size limit
   * @returns An observable of a paged result
   */
  private getGroups(search?: {
    name?: string,
    user_id?: string,
    restaurant_set_id?: string,
  }, limit = 16) {
    let params = [['limit', limit], ['offset', 0]];
    if (search) { params = params.concat(Object.entries(search)); }
    const query = params.filter((p) => !!p[1]).map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<IPageResult<IGroup>>(`api://restaurant-sets?${query}`)
      .pipe(
        catchError((er) => of({
          list: [], total: 0, limit, offset: 0,
        } as IPageResult<IGroup>)),
      );
  }

  /**
   * Fetch a group that has the specified id
   * @param group_id The id of the group we are looking for
   * @param asList Whether to return the result as a page result or just the first matching
   * group
   * @returns If `asList` is true, an Observable which emits one value: a list which should only
   * contain one group if a match was found, or if `asList` is false, the first matching
   * group or null if no matches were found
   */
  getGroup(group_id: string, asList: true): Observable<IPageResult<IGroup>>;
  getGroup(group_id: string, asList: false): Observable<IGroup | null>;
  getGroup(group_id: string, asList: boolean = false) {
    return this.http.get<IPageResult<IGroup>>(`api://restaurant-sets?group_id=${group_id}`)
      .pipe(
        map((result) => {
          if (asList) return result;
          if (result.list.length >= 1) return result.list[0];
          return null;
        }),
      );
  }	

  /**
   * Request restaurants from the server
   * @param search Filter applied to the request
   * @param limit Page size limit
   * @returns An observable of a paged result
   */
  private getRestaurants(search?: {
    name?: string,
    // id?: string,
    user_id?: string,
    // restaurant_id?: string | string[],
    restaurant_set_id?: string,
  }, limit = 16) {
    let params = [['limit', limit], ['offset', 0]];
    if (search) { params = params.concat(Object.entries(search)); }
    const query = params.filter((p) => !!p[1]).map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<IPageResult<IRestaurant>>(`api://restaurants?${query}`)
      .pipe(
        catchError((er) => of({
          list: [], total: 0, limit, offset: 0,
        } as IPageResult<IRestaurant>)),
      );
  }

  /**
   * Fetch a restaurant that has the specified id
   * @param id The id of the restaurant we are looking for
   * @param asList Whether to return the result as a page result or just the first matching
   * restaurant
   * @returns If `asList` is true, an Observable which emits one value: a list which should only
   * contain one restaurant if a match was found, or if `asList` is false, the first matching
   * restaurant or null if no matches were found
   */
  getRestaurant(id: string, asList: true): Observable<IPageResult<IRestaurant>>;
  getRestaurant(id: string, asList: false): Observable<IRestaurant | null>;
  getRestaurant(id: string, asList: boolean = false) {
    return this.http.get<IPageResult<IRestaurant>>(`api://restaurants?id=${id}`)
      .pipe(
        map((result) => {
          if (asList) return result;
          if (result.list.length >= 1) return result.list[0];
          return null;
        }),
      );
  }

  /**
   * Request to be told what restaurant IDs do and don't exist
   * @param restaurantIDs Array of restaurant IDs to verify
   * @param limit Page size limit
   * @param offset Row number to start the page with
   * @returns An observable of an 'existence map' for the requested restaurant IDs
   */
  verifyRestaurantIDs(
    restaurantIDs: string[],
    limit: number = restaurantIDs.length,
    offset: number = 0,
  ): Observable<{ limit: number, total: number, offset: number, list: {[rId: string]: boolean} }> {
    return this.http.post<{
      limit: number, offset: number, total: number,
      list: {[rId: string]: boolean},
    }>(`api://validation/restaurant?limit=${limit}&offset=${offset}`, { restaurant_ids: restaurantIDs });
  }
}
